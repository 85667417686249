import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';
import { NbToastrService, NbDialogService, NbDialogRef } from '@nebular/theme';
import { CurrencyMaskConfig } from 'ng2-currency-mask';
import { environment } from '../../../../../environments/environment.prod';
import { ActionControlListOption } from '../../../../lib/custom-element/action-control-list/action-control.interface';
import { DataManagerFormComponent } from '../../../../lib/data-manager/data-manager-form.component';
import { ContactModel } from '../../../../models/contact.model';
import { Model } from '../../../../models/model';
import { ApiService } from '../../../../services/api.service';
import { CommonService } from '../../../../services/common.service';
import { RootServices } from '../../../../services/root.services';
import { AccountingService } from '../../../accounting/accounting.service';
import { AdminProductService } from '../../../admin-product/admin-product.service';
import { ContractFormComponent } from '../../../contract/contract/contract-form/contract-form.component';
import { ContractPrintComponent } from '../../../contract/contract/contract-print/contract-print.component';
import { MobileAppService } from '../../../mobile-app/mobile-app.service';

@Component({
  selector: 'ngx-acc-manager-account-form',
  templateUrl: './acc-manager-account-form.component.html',
  styleUrls: ['./acc-manager-account-form.component.scss']
})
export class AccManagerAccountFormComponent extends DataManagerFormComponent<Model> implements OnInit {

  componentName: string = 'ContractFormComponent';
  idKey = 'Code';
  baseFormUrl = '/contract/contract/form';
  apiPath = '/acc-manager/accounts';

  env = environment;

  locale = this.cms.getCurrentLoaleDataset();
  curencyFormat: CurrencyMaskConfig = this.cms.getCurrencyMaskConfig();
  numberFormat: CurrencyMaskConfig = this.cms.getNumberMaskConfig();

  // locale = this.commo nService.getCurrentLoaleDataset();
  priceCurencyFormat: CurrencyMaskConfig = { ...this.cms.getCurrencyMaskConfig(), precision: 0 };
  toMoneyCurencyFormat: CurrencyMaskConfig = { ...this.cms.getCurrencyMaskConfig(), precision: 0 };
  quantityFormat: CurrencyMaskConfig = { ...this.cms.getNumberMaskConfig(), precision: 2 };

  towDigitsInputMask = this.cms.createFloatNumberMaskConfig({
    digitsOptional: false,
    digits: 2
  });


  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastrService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public adminProductService: AdminProductService,
    public ref: NbDialogRef<ContractFormComponent>,
    public mobileAppService: MobileAppService,
    public accountingService: AccountingService,
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastrService, dialogService, cms);

    /** Append print button to head card */
    this.actionButtonList.splice(this.actionButtonList.length - 1, 0, {
      name: 'print',
      status: 'primary',
      label: this.cms.textTransform(this.cms.translate.instant('Common.print'), 'head-title'),
      icon: 'printer',
      title: this.cms.textTransform(this.cms.translate.instant('Common.print'), 'head-title'),
      size: 'medium',
      disabled: () => this.isProcessing,
      hidden: () => false,
      click: (event: any, option: ActionControlListOption) => {
        this.preview(option.form);
      },
    });
  }

  select2OptionForTemplate = {
    ...this.cms.makeSelect2AjaxOption('/contract/templates', () => {
      return {
        includeIdText: true,
      };
    }, {
      placeholder: 'Chọn mẫu...',
      limit: 10,
      prepareReaultItem: (item) => {
        return item;
      }
    }),
    placeholder: 'Chọn mẫu...',
  };

  select2OptionForPlatform = {
    placeholder: 'Chọn nền tảng...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    data: []
  };

  // getRequestId(callback: (id?: string[]) => void) {
  //   callback(this.inputId);
  // }

  getRequestId(callback: (id?: string[]) => void) {
    // callback(this.inputId);
    return super.getRequestId(callback);
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init(): Promise<boolean> {

    this.select2OptionForPlatform.data = await this.apiService.getPromise<Model[]>(this.apiPath + '/platforms');
    return super.init().then(async status => {
      if (this.isDuplicate) {
        // Clear id
        this.id = [];
        this.array.controls.forEach((formItem, index) => {
          formItem.get('Code').setValue('');
          // formItem.get('Title').setValue('Copy of: ' + formItem.get('Title').value);

        });
      }
      return status;
    });

  }

  /** Execute api get */
  executeGet(params: any, success: (resources: Model[]) => void, error?: (e: HttpErrorResponse) => void) {
    params['includeAccountPlatforms'] = true;
    super.executeGet(params, success, error);
  }

  async formLoad(formData: Model[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: Model) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      if (itemFormData.AccountPlatforms) {
        const details = this.getAccountPlatforms(newForm);
        details.clear();
        itemFormData.AccountPlatforms.forEach(detail => {
          const newUnitConversionFormGroup = this.makeNewAccountPlatformFormGroup(newForm, detail);
          details.push(newUnitConversionFormGroup);
          const comIndex = details.length - 1;
          this.onAddAccountPlatformFormGroup(newForm, newUnitConversionFormGroup, comIndex);
        });
      }

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }
    });

  }

  makeNewFormGroup(data?: Model): FormGroup {
    const newForm = this.formBuilder.group({
      SystemUuid: [],
      Name: [null, Validators.required],
      Email: [null, Validators.required],
      Avatar: [],
      Banner: [],
      AccountPlatforms: this.formBuilder.array([]),
    });
    if (data) {
      // data['Code_old'] = data['Code'];
      newForm.patchValue(data);
    }
    return newForm;
  }
  onAddFormGroup(index: number, newForm: FormGroup, formData?: Model): void {
    super.onAddFormGroup(index, newForm, formData);
  }
  onRemoveFormGroup(index: number): void {

  }

  /** AccountPlatform Form */
  makeNewAccountPlatformFormGroup(parentFormGroup: FormGroup, data?: Model): FormGroup {
    let newForm: FormGroup;
    newForm = this.formBuilder.group({
      SystemUuid: [],
      Platform: [],
      Uuid: [],
      BioLink: [],
      // Avatar: [],
      Token2Fa: [],
      Token2FaApp: [],
      Password: [],
    });

    if (data) {
      if (data?.Product && Array.isArray(data.Product['Units'])) {
        const unitControl = newForm.get('Unit');
        newForm['UnitList'] = data?.Product['Units'];
      }
      newForm.patchValue(data);
    }
    return newForm;
  }
  getAccountPlatforms(parentFormGroup: FormGroup) {
    return parentFormGroup.get('AccountPlatforms') as FormArray;
  }
  addAccountPlatformFormGroup(parentFormGroup: FormGroup) {
    const newChildFormGroup = this.makeNewAccountPlatformFormGroup(parentFormGroup);
    const detailsFormArray = this.getAccountPlatforms(parentFormGroup);
    detailsFormArray.push(newChildFormGroup);
    const noFormControl = newChildFormGroup.get('No');
    if (!noFormControl.value) {
      noFormControl.setValue(detailsFormArray.length);
    }
    detailsFormArray.controls = [...detailsFormArray.controls];
    this.onAddAccountPlatformFormGroup(parentFormGroup, newChildFormGroup, detailsFormArray.length - 1);
    return false;
  }
  removeAccountPlatformGroup(parentFormGroup: FormGroup, detail: FormGroup, index: number) {
    const details = this.getAccountPlatforms(parentFormGroup);
    details.removeAt(index);
    details.controls = [...details.controls];
    this.onRemoveAccountPlatformFormGroup(parentFormGroup, detail);
    return false;
  }

  purchaseProductMap: { [key: string]: Model } = {};
  onAddAccountPlatformFormGroup(parentFormGroup: FormGroup, newChildFormGroup: FormGroup, index: number) {
    if (parentFormGroup['__disabled']) {
      newChildFormGroup.disable();
    } else {
      newChildFormGroup.enable();
    }
  }
  onRemoveAccountPlatformFormGroup(parentFormGroup: FormGroup, detailFormGroup: FormGroup) {
  }

  /**
   * Choose product form recent purchase order and add to details
   * @param parentFormGroup 
   * @returns 
   */
  addMultiProducts(parentFormGroup: FormGroup) {

    const filter = { group_Object: true, group_Product: true, includeUnit: true };
    const objectId = this.cms.getObjectId(parentFormGroup.get('Object').value);
    if (objectId) {
      filter['eq_Object'] = objectId;
      filter['sort_DateOfVoucher'] = 'desc';
    }


    return false;
  }
  /** End AccountPlatform Form */

  goback(): false {
    // super.goback();
    if (this.mode === 'page') {
      this.router.navigate(['/collaborator/opportunity/list']);
    } else {
      this.ref.close();
    }
    return false;
  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void { }
  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void { }


  async preview(formItem: FormGroup) {
    const data: Model = formItem.value;
    this.cms.openDialog(ContractPrintComponent, {
      context: {
        showLoadinng: true,
        title: 'Xem trước',
        data: [data],
        idKey: ['Code'],
        onSaveAndClose: (priceReport: Model) => {
          this.saveAndClose();
        },
        onSaveAndPrint: (priceReport: Model) => {
          this.save();
        },
      },
    });
    return false;
  }

  getRawFormData() {
    return super.getRawFormData();
  }

  onAObjectChange(formGroup: FormGroup, selectedData: ContactModel, formIndex?: number) {
    // console.info(item);

    if (!this.isProcessing) {
      if (selectedData && !selectedData['doNotAutoFill']) {

        if (selectedData.id) {
          formGroup.get('AObjectName').setValue(selectedData.Name);
          formGroup.get('AObjectPhone').setValue(selectedData.Phone);
          formGroup.get('AObjectEmail').setValue(selectedData.Email);
          formGroup.get('AObjectAddress').setValue(selectedData.Adress);
        }
      }
    }
  }
  onADelegatorChange(formGroup: FormGroup, selectedData: ContactModel, formIndex?: number) {
    // console.info(item);

    if (!this.isProcessing) {
      if (selectedData && !selectedData['doNotAutoFill']) {

        if (selectedData.id) {
          formGroup.get('ADelegatorName').setValue(selectedData.Name);
        }
      }
    }
  }
  onASigningDelegatorChange(formGroup: FormGroup, selectedData: ContactModel, formIndex?: number) {
    // console.info(item);

    if (!this.isProcessing) {
      if (selectedData && !selectedData['doNotAutoFill']) {

        if (selectedData.id) {
          formGroup.get('ASigningDelegatorName').setValue(selectedData.Name);
        }
      }
    }
  }
  onBObjectChange(formGroup: FormGroup, selectedData: ContactModel, formIndex?: number) {
    // console.info(item);

    if (!this.isProcessing) {
      if (selectedData && !selectedData['doNotAutoFill']) {

        if (selectedData.id) {
          formGroup.get('BObjectName').setValue(selectedData.Name);
          formGroup.get('BObjectIdentityPhoneNumber').setValue(selectedData.Phone);
          formGroup.get('BObjectEmail').setValue(selectedData.Email);
          formGroup.get('BObjectAddress').setValue(selectedData.Address);
        }
      }
    }
  }

  onTemplateChange(formGroup: FormGroup, selectedData: Model, formIndex?: number) {
    // console.info(item);

    if (!this.isProcessing) {
      if (selectedData && !selectedData['doNotAutoFill']) {

        if (selectedData.id) {
          this.apiService.getPromise<Model[]>('/contract/templates/' + selectedData.id).then(rs => rs[0]).then(template => {
            if (!formGroup.get('Content').value?.trim()) {
              formGroup.get('Content').setValue(template.Content);
            } else {
              this.cms.showDialog('Chọn mẫu hợp đồng', 'Bạn có muốn ghi đè nội dung hợp đồng hiện tại không ?', [
                {
                  label: 'Trở về',
                  status: 'basic',
                  outline: true,
                  action: () => {

                  },
                },
                {
                  label: 'Ghi đè',
                  status: 'danger',
                  action: () => {
                    formGroup.get('Content').setValue(template.Content);
                  },
                },
              ]);
            }
          });
        }
      }
    }
  }

}
