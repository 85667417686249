<form [formGroup]="form" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card [nbSpinner]="isProcessing" class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Tài khoản' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">
                        <div class="row">
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Name" label="Common.name" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                    <input type="text" nbInput fullWidth formControlName="Name" placeholder="{{'Common.name' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Email" label="Common.email" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                    <input type="text" nbInput fullWidth formControlName="Email" placeholder="{{'Common.email' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Avatar" label="Common.avatar" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                    <ngx-file-input formControlName="Avatar" [alowUploadByLink]="true"></ngx-file-input>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Banner" label="Common.banner" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                    <ngx-file-input formControlName="Banner" [alowUploadByLink]="true"></ngx-file-input>
                                </ngx-form-group>
                            </div>
                        </div>
                        <!-- AccountPlatform form -->
                        <label class="label">{{'Platform' | translate | headtitlecase}}</label>
                        <div class="row fit-row form-detail-header">
                            <div class="fit-fist-col label column">Stt</div>
                            <div class="row fit-content-column">
                                <div class="col-sm-2 label column">{{'Nền tảng' | translate | headtitlecase}}</div>
                                <div class="col-sm-2 label column">{{'Uuid' | translate | headtitlecase}}</div>
                                <div class="col-sm-2 label column">{{'Bio link' | translate | headtitlecase}}</div>
                                <div class="col-sm-2 label column">{{'2FaApp' | translate | headtitlecase}}</div>
                                <div class="col-sm-2 label column">{{'2FaApp' | translate | headtitlecase}}</div>
                                <div class="col-sm-2 label column">{{'Token' | translate | headtitlecase}}</div>
                            </div>
                            <div class="fit-last-col column">
                                <button class="fit-control-button" nbButton status="success" (click)="addDetailFormGroup(formItem)" size="medium" hero>
                                    <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                                </button>
                            </div>
                        </div>
                        <div formArrayName="AccountPlatforms" class="form-details">
                            <div class="form-detail-item" *ngFor="let detail of getAccountPlatforms(formItem).controls; let ir=index" [formGroup]="detail">
                                <div class="row fit-row">
                                    <div class="fit-fist-col label">
                                        <div class="form-group">
                                            <input class="fit-sequence" nbInput fullWidth disabled="true" value="{{ ir + 1 }}">
                                        </div>
                                    </div>
                                    <div class="row fit-content-column">
                                        <div class="col-sm-12">
                                            <div class="row">
                                                <div class="col-sm-2">
                                                    <ngx-form-group [formGroup]="detail" name="Platform" label="Nền tảng" [array]="getAccountPlatforms(formItem)" [index]="ir" [allowCopy]="true" [required]="false" [hideLabel]="true" [customIcons]="customIcons">
                                                        <ngx-select2 formControlName="Platform" [select2Option]="select2OptionForPlatform"></ngx-select2>
                                                    </ngx-form-group>
                                                </div>
                                                <div class="col-sm-2">
                                                    <ngx-form-group [formGroup]="detail" name="Uuid" label="Uuid" [array]="getAccountPlatforms(formItem)" [index]="ir" [allowCopy]="true" [required]="true" [hideLabel]="true" [touchedValidate]="false">
                                                        <input type="text" nbInput fullWidth formControlName="Uuid" placeholder="{{'Uuid' | translate | headtitlecase}}">
                                                    </ngx-form-group>
                                                </div>
                                                <div class="col-sm-2">
                                                    <ngx-form-group [formGroup]="detail" name="BioLink" label="Bio link" [array]="getAccountPlatforms(formItem)" [index]="ir" [allowCopy]="true" [required]="true" [hideLabel]="true" [touchedValidate]="false">
                                                        <input type="text" nbInput fullWidth formControlName="BioLink" placeholder="{{'Bio link' | translate | headtitlecase}}">
                                                    </ngx-form-group>
                                                </div>
                                                <div class="col-sm-2">
                                                    <ngx-form-group [formGroup]="detail" name="Token2FaApp" label="2FaApp" [array]="getAccountPlatforms(formItem)" [index]="ir" [allowCopy]="true" [required]="true" [hideLabel]="true" [touchedValidate]="false">
                                                        <input type="text" nbInput fullWidth formControlName="Token2FaApp" placeholder="{{'2FaApp' | translate | headtitlecase}}">
                                                    </ngx-form-group>
                                                </div>
                                                <div class="col-sm-2">
                                                    <ngx-form-group [formGroup]="detail" name="Token2Fa" label="2Fa" [array]="getAccountPlatforms(formItem)" [index]="ir" [allowCopy]="true" [required]="true" [hideLabel]="true" [touchedValidate]="false">
                                                        <input type="text" nbInput fullWidth formControlName="Token2Fa" placeholder="{{'2Fa' | translate | headtitlecase}}">
                                                    </ngx-form-group>
                                                </div>
                                                <div class="col-sm-2">
                                                    <ngx-form-group [formGroup]="detail" name="Password" label="Password" [array]="getAccountPlatforms(formItem)" [index]="ir" [allowCopy]="true" [required]="true" [hideLabel]="true" [touchedValidate]="false">
                                                        <input type="text" nbInput fullWidth formControlName="Password" placeholder="{{'Password' | translate | headtitlecase}}">
                                                    </ngx-form-group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="fit-last-col">
                                        <div class="form-group" style="text-align: right;">
                                            <button class="fit-control-button" [disabled]="!form.valid || isProcessing" nbButton status="danger" hero size="medium" (click)="removeAccountPlatformGroup(formItem, detail, ir)">
                                                <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-detail-item">
                                <div class="row fit-row form-detail-footer">
                                    <div class="fit-fist-col label">

                                    </div>
                                    <div class="row fit-content-column">
                                        <div class="col-sm-12">
                                            <div class="row">
                                                <div class="col-sm-4">

                                                </div>
                                                <div class="col-sm-6" style="text-align: right; font-weight: bold;">Tổng cộng:</div>
                                                <div class="col-sm-2" style="text-align: right; font-weight: bold; padding-right: 1.7rem;">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="fit-last-col">
                                        <div class="form-group" style="text-align: right;">
                                            <button class="fit-control-button" [disabled]="!form.valid || isProcessing" nbButton [outline]="true" status="success" (click)="addAccountPlatformFormGroup(formItem)" size="medium" hero>
                                                <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12" style="text-align: right;">

                            </div>
                        </div>
                        <!-- End AccountPlatform form -->
                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <button nbButton hero status="primary" (click)="preview(formItem)" [disabled]="!form.valid || isProcessing">
                            <nb-icon nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
                        </button>
                        <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
                        <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="save()">{{'Common.save' | translate | headtitlecase}}</button>
                    </div>
                </nb-card-footer>
            </nb-card>
        </div>
    </div>
</form>