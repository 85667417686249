import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbToastrService, NbThemeService, NbDialogRef } from '@nebular/theme';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { AgDateCellRenderer } from '../../../../lib/custom-element/ag-list/cell/date.component';
import { AgTextCellRenderer } from '../../../../lib/custom-element/ag-list/cell/text.component';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeStateColDef } from '../../../../lib/custom-element/ag-list/column-define/state.define';
import { agMakeTagsColDef } from '../../../../lib/custom-element/ag-list/column-define/tags.define';
import { AgSelect2Filter } from '../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { Model } from '../../../../models/model';
import { ApiService } from '../../../../services/api.service';
import { CommonService } from '../../../../services/common.service';
import { RootServices } from '../../../../services/root.services';
import { ContractStateMap } from '../../../contract/contract.state.map';
import { ContractFormComponent } from '../../../contract/contract/contract-form/contract-form.component';
import { ContractListComponent } from '../../../contract/contract/contract-list/contract-list.component';
import { ContractPrintComponent } from '../../../contract/contract/contract-print/contract-print.component';
import { AccManagerAccountFormComponent } from '../acc-manager-account-form/acc-manager-account-form.component';
import { AccManagerAccountPrintComponent } from '../acc-manager-account-print/acc-manager-account-print.component';

@Component({
  selector: 'ngx-acc-manager-account-list',
  templateUrl: './acc-manager-account-list.component.html',
  styleUrls: ['./acc-manager-account-list.component.scss']
})
export class AccManagerAccountListComponent extends AgGridDataManagerListComponent<Model, ContractFormComponent> implements OnInit {

  componentName: string = 'ContractListComponent';
  formPath = '/contract/contract/form';
  apiPath = '/acc-manager/accounts';
  idKey = ['SystemUuid'];

  // Use for load settings menu for context
  feature = {
    Module: { id: 'Ecard', text: 'Hợp đồng' },
    Feature: { id: 'Ecard', text: 'Hợp đồng' }
  };

  printDialog = AccManagerAccountPrintComponent;
  formDialog = AccManagerAccountFormComponent;

  // AG-Grid config
  public rowHeight: number = 50;
  // @Input() suppressRowClickSelection = false;

  @Input() gridHeight = '100%';

  static processingMap = {};

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<ContractListComponent>,
    public datePipe: DatePipe,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    }

    this.pagination = false;
    // this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
  }

  async init() {
    const processingMap = ContractStateMap.contractStateMap;

    return super.init().then(async state => {


      this.actionButtonList.unshift({
        type: 'button',
        name: 'registerProBoxVnAccount',
        status: 'danger',
        label: 'Tạo tài khoản ProBox.vn',
        title: 'Tạo tài khoản ProBox.vn',
        size: 'medium',
        icon: 'flash-outline',
        disabled: () => {
          return this.selectedIds.length == 0;
        },
        click: () => {
          this.apiService.putPromise(this.apiPath, { generateProBoxVnAccounts: true }, this.selectedItems.map(m => ({ SystemUuid: m.SystemUuid }))).then(rs => {
            console.log(rs);
          });
        }
      });

      await this.cms.waitForLanguageLoaded();

      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
        },
        // {
        //   headerName: 'Mã',
        //   field: 'Code',
        //   width: 140,
        //   filter: 'agTextColumnFilter',
        //   pinned: 'left',
        // },
        // {
        //   headerName: 'Bên A',
        //   field: 'AObject',
        //   // pinned: 'left',
        //   width: 200,
        //   cellRenderer: AgTextCellRenderer,
        //   filter: AgSelect2Filter,
        //   filterParams: {
        //     select2Option: {
        //       ...this.cms.makeSelect2AjaxOption('/contact/contacts', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
        //         placeholder: 'Chọn liên hệ...', limit: 10, prepareReaultItem: (item) => {
        //           item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
        //           return item;
        //         }
        //       }),
        //       multiple: true,
        //       logic: 'OR',
        //       allowClear: true,
        //     }
        //   },
        // },
        {
          headerName: 'Tên',
          field: 'Name',
          width: 350,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Email',
          field: 'Email',
          width: 200,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Người tạo',
          field: 'Creator',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/user/users', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
                placeholder: 'Chọn người tạo...', limit: 10, prepareReaultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'];
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Ngày tạo',
          field: 'Created',
          width: 180,
          filter: 'agDateColumnFilter',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
        },
        {
          ...agMakeTagsColDef(this.cms, (tag) => {
            this.cms.previewVoucher(tag.type, tag.id);
          }),
          headerName: 'Chứng từ liên quan',
          field: 'RelativeVouchers',
          width: 330,
        },
        {
          ...agMakeStateColDef(this.cms, processingMap, (data) => {
            // this.preview([data]);
            if (this.cms.getObjectId(data.State) == 'PROCESSING') {
              this.openForm([data.Code]);
            } else {
              this.preview([data]);
            }
          }),
          headerName: 'Trạng thái',
          field: 'State',
          width: 300,
        },
        // {
        //   headerName: 'Số điện thoại',
        //   field: 'Phone',
        //   width: 200,
        //   filter: 'agTextColumnFilter',
        //   autoHeight: true,
        // },
        // {
        //   headerName: 'Email',
        //   field: 'Email',
        //   width: 200,
        //   filter: 'agTextColumnFilter',
        //   autoHeight: true,
        // },
        // {
        //   headerName: 'Website',
        //   field: 'Website',
        //   width: 200,
        //   filter: 'agTextColumnFilter',
        //   autoHeight: true,
        // },
        // {
        //   headerName: 'Đại chỉ',
        //   field: 'Address',
        //   width: 500,
        //   filter: 'agTextColumnFilter',
        //   autoHeight: true,
        // },
        {
          headerName: 'SystemUuid',
          field: 'SystemUuid',
          width: 150,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, true),
        }
      ] as ColDef[]);

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  // @Input() getRowHeight = (params: RowHeightParams<CommercePosOrderModel>) => {
  //   return 123;
  // }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['includeRelativeVouchers'] = true;
    return params;
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: Model[]) => void, onDialogClose?: () => void) {
    this.cms.openDialog(ContractFormComponent, {
      context: {
        inputMode: 'dialog',
        inputId: ids,
        onDialogSave: (newData: Model[]) => {
          if (onDialogSave) onDialogSave(newData);
        },
        onDialogClose: () => {
          if (onDialogClose) onDialogClose();
        },
      },
    });
    return false;
  }

  onGridReady(params) {
    super.onGridReady(params);
  }

}
